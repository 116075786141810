<template>
  <div class="address-page">
    <headerBar :title="i18n.t('bind.quickTitle')"  :showArrow="true" @back="back"></headerBar>

    <div class="address-form">
      <div class="form-item" >
        <!--        <div class="label">{{i18n.t('bind.Card')}}</div>-->
        <div class="label">{{i18n.t('bind.Card1')}}</div>
        <div class="input ">
            <input type="text" :readonly="readonly" v-model="form.Card" :placeholder="i18n.t('bind.placeholder10')">
        </div>
      </div>
      <div class="form-item">
        <div class="label">{{i18n.t('bind.RealName')}}</div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.RealName" :placeholder="i18n.t('bind.placeholder5')">
        </div>
      </div>
<!--      截止日期 -->
      <div class="form-item" >
        <div class="label">{{i18n.t('bind.deadline')}}</div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.Deadline" :placeholder="i18n.t('bind.placeholder5')">
        </div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.DeadlineMonth" :placeholder="i18n.t('bind.placeholder5')">
        </div>
      </div>

      <div class="form-item" >
        <div class="label">{{i18n.t('bind.securityCode')}}</div>
        <div class="input ">
          <input type="text" :readonly="readonly" v-model="form.SecurityCode" :placeholder="i18n.t('bind.placeholder11')">
        </div>
      </div>

    </div>


    <div class="btn" v-show="readonly === false">
      <Button @click="submit">{{i18n.t('address.btn')}}</Button>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
import headerBar from '@/components/header'
import {addBank, addCard, addUsdt, doWithdraw, getBankList, getUsdtList, QuickCardBind} from "@/api/user";
export default {
  components: {
    headerBar,
    Button
  },
  data() {
    return {
      form: {
        RealName: '',
        Card: '',
        Deadline: '',
        SecurityCode: '',
        DeadlineMonth: '',
      },
      readonly:true,
      userMoney: '0.00',
    }
  },
  mounted() {
    this.init()
  },
  methods: {
      init(){
          getBankList().then(res =>{
              if (res.code === 0) {
                  console.log(res.data.Items)
                  if(res.data.Items.length === 0) {
                      this.readonly = false
                  } else {
                      this.form = res.data.Items[0]
                  }
              }
          })
      },
    back() {
      this.$router.push({
        name: "mine"
      })
    },
    setMoney(money) {
      this.form.number =  money
    },
    submit() {
        let form = new FormData()
        form.append('RealName', this.form.RealName)
        form.append('Card', this.form.Card)
        form.append('Deadline', this.form.Deadline)
        form.append('DeadlineMonth', this.form.DeadlineMonth)
        form.append('SecurityCode', this.form.SecurityCode)
      QuickCardBind(form).then(res => {
            if (res.code == 0) {
              this.back()
                this.$toast(res.msg)
            } else {
                this.$toast(res.msg)
            }
        })
    },
    setAll() {
      this.form.money = this.userMoney
    }
  }
}
</script>
<style scoped>
.label-self::before{
  content: none !important; /* 或 content: ""; */
}
</style>